import React, { ReactNode } from 'react';
import { Widget } from '../../../../../../../components';
import s from '../../../Dashboard.module.scss';
import { DASHBOARD_PORTFOLIO_FEES_HELP_TEXT } from '../../../../../../../../../services/constants/tooltipContextHelp';
import { Table } from '@iliotech/storybook';
import { ColumnProps } from '@iliotech/storybook/build/components/Table/Table.types';
import { explorerDecimalNumberRenderer } from '../../../../../../PortfolioExplorer/utils/explorerCurrencyRenderer';

interface IProps {
  feesSinceInception: IFeesSinceInceptionData[];
  selectedCurrencySymbol: string;
}

const currencyCellRenderer = (currencySymbol: string | undefined, decimalPlaces: number = 2) => (
  rowData: any,
  dataKey: string
) => {
  const value = rowData[dataKey];
  if (!value) {
    return <div />;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      {currencySymbol || ''}&nbsp;
      {explorerDecimalNumberRenderer(rowData, dataKey, true)}
    </div>
  ) as ReactNode;
};

const COLUMNS: (selectedCurrencySymbol: string) => ColumnProps[] = (selectedCurrencySymbol) => [
  {
    dataKey: 'name',
    label: 'Date',
    align: 'center',
    flexGrow: 1,
  },
  {
    dataKey: 'commission',
    label: 'Commission',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'tradeCosts',
    label: 'Tax / Other Charges',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'custodianFees',
    label: 'Custodian Fees',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'managementFees',
    label: 'Management Fees',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'performanceFee',
    label: 'Performance Fees',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'miscCharges',
    label: 'Misc Charge',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'interestCharges',
    label: 'Interest Charge',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'total',
    label: 'Total',
    align: 'center',
    cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
    flexGrow: 1,
  },
  {
    dataKey: 'percentageFromNav',
    label: '% of Nav',
    align: 'center',
    flexGrow: 1,
  },

  // {
  //   dataKey: 'tax',
  //   label: 'Tax',
  //   align: 'center',
  //   cellRenderer: currencyCellRenderer(selectedCurrencySymbol),
  //   flexGrow: 1,
  // },
];

const FeesAndChargesTable = ({ feesSinceInception, selectedCurrencySymbol }: IProps) => {
  return (
    <Widget title={'Details'} className={s.defaultContainer} titleInfo={DASHBOARD_PORTFOLIO_FEES_HELP_TEXT.TableInfo}>
      <Table
        rowHeight={30}
        isTree={true}
        light={false}
        columns={COLUMNS(selectedCurrencySymbol)}
        defaultExpandedRowKeys={[feesSinceInception[0]?.name]}
        data={feesSinceInception.map((fee) => ({
          ...fee,
          children: [...fee.monthsCommissions?.map((subFee) => ({ ...subFee, percentageFromNav: '' }))],
        }))}
        rowKey={'name'}
      />
    </Widget>
  );
};

export default FeesAndChargesTable;
